if (typeof elementInView === 'undefined') {
	// Get animatable scroll elements
	const scrollElements = Array.prototype.slice.call(document.querySelectorAll('[data-scroll], .data-scroll'));

	// Get load on scroll elements
	const loadHandlers = Array.prototype.slice.call(document.querySelectorAll('[data-unloaded], .data-unloaded'));

	// Create full node list
	scrollElements.push.apply(scrollElements, loadHandlers);

	// Check if element is in view
	const elementInView = (el, dividend = 1) => {
		const elementTop = el.getBoundingClientRect().top;

		return (
			elementTop <=
			(window.innerHeight || document.documentElement.clientHeight) / dividend
		);
	};

	// Check if out of view
	const elementOutofView = (el) => {
		const elementTop = el.getBoundingClientRect().top;

		return (
			elementTop > (window.innerHeight || document.documentElement.clientHeight)
		);
	};

	// In view add .scrolled class
	// remove data-unloaded from load elements
	const displayScrollElement = (element) => {
		element.classList.add('scrolled');
		if (element.dataset.unloaded) {
			element.src = element.dataset.src;
			delete element.dataset.unloaded;
		}
	};

	// Remove .scrolled class when off screen
	const hideScrollElement = (element) => {
		element.classList.remove('scrolled');
	};

	// Loop scroll elements array checking conditions
	const handleScrollAnimation = () => {
		scrollElements.forEach((el) => {
			if (elementInView(el, 1.25)) {
				displayScrollElement(el);
			} else if (elementOutofView(el)) {
				hideScrollElement(el);
			}
		});
	};

	// On scroll event
	window.addEventListener('scroll', () => {
		handleScrollAnimation();
	});
	// Also check on load
	handleScrollAnimation();
}

const toggleNav = document.querySelector('#toggle-nav');
if (toggleNav) {
	toggleNav.addEventListener('click', () => {
		document.querySelector('body').classList.toggle('nav-open');

		if (document.querySelector('.page-template-page-alice-underground')) {
			if (document.querySelector('.nav-open')) {
				document.getElementById('toggle-nav').querySelector('use').setAttribute('href', '#icons-icon-close');
			} else {
				document.getElementById('toggle-nav').querySelector('use').setAttribute('href', '#icons-icon-diamond');
			}
		}
	});

	document.querySelectorAll('#menu-primary-menu .menu-item').forEach((_sel) => {
		_sel.addEventListener('click', () => {
			document.querySelector('body').classList.remove('nav-open');
		});
	});
}

Array.prototype.sample = function() {
	return this[ Math.floor(Math.random() * this.length) ];
};

const teasePage = document.querySelector('#tease-masthead');
if (teasePage) {
	function updateCountdown() {
		const timespan = countdown(new Date(2022, 5, 17, 10));
		document.querySelector('#tease').innerHTML = _farlo.helpers.numeric.prependZero(timespan.days) + ' : ' + _farlo.helpers.numeric.prependZero(timespan.hours) + ' : ' + _farlo.helpers.numeric.prependZero(timespan.minutes) + ' : ' + _farlo.helpers.numeric.prependZero(timespan.seconds);
		requestAnimationFrame(updateCountdown);
	} updateCountdown();
}

function animateImage() {
	if (document.querySelector('.masthead')) {
		document.querySelector('.masthead').classList.toggle('animation-loaded');
	}
}

document.querySelectorAll('[data-lightbox]').forEach(function(_sel) {
	_farloUi.tools.lightbox.trigger(_sel);
});

if (document.querySelector('.ukds-mc-form-item-4-2')) {
	fetch('https://geolocation-db.com/json/').then((res) => res.json()).then((response) => {
		document.querySelector('.ukds-mc-form-item-4-2').querySelector('input').value = response.country_name;
	}).catch((data, status) => {
		document.querySelector('.ukds-mc-form-item-4-2').querySelector('input').value = '';
	});
}

if (document.querySelector('.ukds-mc-form-item-4-3')) {
	if (typeof _farlo.helpers.urls.getQueryValue('utm_source') !== 'undefined') {
		document.querySelector('.ukds-mc-form-item-4-3').querySelector('input').value = _farlo.helpers.urls.getQueryValue('utm_source');
	}
}

if (document.querySelector('.collapsible-header')) {
	document.querySelectorAll('.collapsible-header').forEach((_sel) => {
		_sel.addEventListener('click', (e) => {
			e.stopPropagation();
			_sel.parentElement.classList.toggle('collapse-active');
			const elem = _sel.nextElementSibling;
			elem.style.height = ((elem.style.height == 'auto') ? elem.scrollHeight + 'px' : elem.style.height);
			animate(elem);
			function animate(elem) {
				setTimeout(function() {
					return new Promise((resolve, reject) => {
						elem.style.height = ((elem.style.height != '0px') && elem.style.height ? '' : (elem.scrollHeight + 20) + 'px');
					});
				}, 50);
			}
		});
	});
}

function toggleCastCrewOverlay(_activate) {
	if (_activate) {
		document.querySelector('.cast-crew-main').classList.add('cast-crew-active');
	} else {
		document.querySelector('.cast-crew-main').classList.remove('cast-crew-active');
	}
}

if (document.querySelector('.cast-crew-item')) {
	document.querySelectorAll('[data-close]').forEach((_close) => {
		_close.addEventListener('click', function() {
			toggleCastCrewOverlay(false);
		});
	});

	document.querySelectorAll('[data-trigger]').forEach((_elem) => {
		_elem.addEventListener('click', (_sel) => {
			const elem = document.querySelector("[data-index='" + _elem.dataset.trigger + "']");
			if (elem) {
				toggleCastCrewOverlay(true);
				if (elem.querySelector("[data-content='image']")) {
					document.querySelector('#crew-active').classList.remove('no-image');
					document.querySelector('[data-fill="image"]').src = elem.querySelector("[data-content='image']").src;
				} else {
					document.querySelector('#crew-active').classList.add('no-image');
				}
				document.querySelector('[data-fill="name"]').innerText = elem.querySelector("[data-content='name']").innerText;
				document.querySelector('[data-fill="role"]').innerText = elem.querySelector("[data-content='role']").innerText;
				document.querySelector('[data-fill="bio"]').innerText = elem.querySelector("[data-content='bio']").innerText;
			}
		});
	});
}

if (document.querySelector('[data-toggle]')) {
	document.querySelectorAll('[data-toggle]').forEach((_sel) => {
		_sel.addEventListener('click', (e) => {
			e.stopPropagation();
			document.querySelectorAll('[data-toggle-hide]').forEach((_hide) => {
				_hide.style.opacity = 0;
				_hide.style.display = 'none';
				document.getElementById(_farlo.helpers.urls.getHash(_sel.href)).style.display = 'flex';
				document.getElementById(_farlo.helpers.urls.getHash(_sel.href)).style.opacity = 1;
			});
			setTimeout(() => {
				resizeCard();
			}, 50);
		});
	});
}

var resizeCard = function() {
	document.querySelectorAll('.card, .card-flip__info').forEach((_sel) => {
		_sel.style.height = (_sel.clientWidth * 1.401) + 'px';
	});
};

if (document.querySelector('.page-template-page-alice-underground')) {
	if (document.querySelector('#header-button')) {
		document.getElementById('header-button').addEventListener('mouseover', () => {
			document.querySelector('#header-button').querySelector('use').setAttribute('href', '#icons-icon-heart');
		});
		document.getElementById('header-button').addEventListener('mouseout', () => {
			document.querySelector('#header-button').querySelector('use').setAttribute('href', '#icons-icon-ticket');
		});
	}
}
if (document.querySelector('.page-template-page-alice-underground .card')) {
	_farloUi.handlers.resize.push(resizeCard);
	resizeCard();
}

if (document.querySelector('#pick-a-card')) {
	const cardStyles = document.createElement('style');
	document.querySelector('body').appendChild(cardStyles);

	const updateCardStyles = () => {
		const offset = window.pageYOffset;
		cardStyles.innerText = `
      #pick-a-card:before {
        transform:translateY(${ (offset * 0.015) * -1 }%);
      }
      #pick-a-card:after {
        transform:translateY(${ (offset * 0.01) * -1 }%);
      }
    `;
	};
	_farloUi.handlers.scroll.push(updateCardStyles);
	updateCardStyles();
}

if (document.querySelector('#widget-onthe-stage')) {
	const setWidgetHeight = () => {
		const frameHeight = parseInt((window.innerHeight - document.querySelector('#page-header').getBoundingClientRect().height), 10);
		document.documentElement.style.setProperty('--frame-height', frameHeight);
	};
	_farloUi.handlers.resize.push(setWidgetHeight);
	setWidgetHeight();
} else {
	document.documentElement.style.setProperty('--frame-height', 0);
}

if (typeof (gform) !== 'undefined') {
	gform.addFilter('gform_datepicker_options_pre_init', function(optionsObj, formId, fieldId) {
		if (formId == 1 && (
			fieldId == 7 ||
      fieldId == 18)) {
			optionsObj.minDate = new Date('2023-03-01');
		}
		return optionsObj;
	});
}

if (document.querySelector('.site-open-table__trigger')) {
	const opentableUrl = 'https://www.opentable.co.uk/widget/reservation/loader?rid=278892&type=standard&theme=tall&color=6&dark=true&iframe=true&domain=couk&lang=en-GB&newtab=false&ot_source=Restaurant%20website';
	document.querySelectorAll('.site-open-table__trigger').forEach((_sel) => {
		_sel.querySelector('a').addEventListener('click', (e) => {
			const s = document.createElement('script');
			const div = document.createElement('div');
			const button = document.createElement('button');

			e.preventDefault();

			div.className = 'site-open-table__close';
			button.className = 'site-open-table__close-button';

			s.setAttribute('src', opentableUrl);
			s.setAttribute('class', 'site-open-table__script');
			e.currentTarget.setAttribute('disabled', 'disabled');
			div.appendChild(button);
			e.currentTarget.parentNode.appendChild(div);
			e.currentTarget.parentNode.appendChild(s);
		});
	});

	document.addEventListener('click', (e) => {
		if (document.querySelector('[id^="ot-widget"]') !== null) {
			const openTableWidgets = document.querySelectorAll('[id^="ot-widget"]');

			for (let i = 0; i < openTableWidgets.length; i += 1) {
				if (!openTableWidgets[ i ].isSameNode(e.target)) {
					openTableWidgets[ i ].parentNode.parentNode.querySelector('.site-open-table__trigger').removeAttribute('disabled');
					openTableWidgets[ i ].parentNode.querySelector('.site-open-table__close').remove();
					openTableWidgets[ i ].remove();
				}
			}
		}
	});
}
